import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useAppContext } from '../../hooks/useAppContext';
import BenefitsInfo from '@/components/BenefitsInfo';
import { IProductsWrapper } from './types';
import { ExistingCustomerNavBar, getActiveAccountSlug, MobileAppNavBar, cn } from 'uibook';
import { baseUrl, defaultLinks, addTechLink } from '@/components/navbar/constants';
import AccountBanner from '@/components/navbar/AccountBanner';
import { useCustomerContext } from '../../hooks/useCustomerContext';
import Footer from '@/components/footer';
import TrustPilotBanner from '@/components/trustpilotbanner';
import { ThemeProvider } from 'styled-components';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useRayloCookiesContext } from '@/hooks/useRayloCookiesContext';
import { getBenefitsInfoContent } from '../BenefitsInfo/benefitsInfoContent';
import { SmbInfoModal } from '../SmbInfoModal/SmbInfoModal';

export const ProductsWrapper = ({ children, context }: IProductsWrapper) => {
  const { hasLoggedInCustomer, eligibleForAddTech, accountSwitcherData, isMobileApp } =
    useCustomerContext();
  const { consumerIsPersonal, consumerIsBusiness } = useConsumerTypeContext();
  const { isRayloPay, theme } = useAppContext();
  const router = useRouter();
  const benefitsListItems = getBenefitsInfoContent(isRayloPay, router.asPath);

  const { boot, update, shutdown } = useIntercom();
  const { cookieValues, setDomainCookie } = useRayloCookiesContext();

  useEffect(() => {
    if (isRayloPay) {
      boot({
        customLauncherSelector: '.launch-intercom',
      });
      if (router.asPath.includes('products/')) {
        update({
          alignment: 'middle',
          horizontalPadding: 32,
          verticalPadding: 150,
        });
      }
    }
  }, [isRayloPay, boot, update, router?.asPath]);

  const isProductDescriptionPage = router?.asPath.includes('products/');
  const isAboutPage = router?.asPath.includes('about');

  const activeAccount = useMemo(
    () =>
      accountSwitcherData?.accounts.find(
        (account) => account.id === cookieValues?.raylo_activeAccountId,
      ),
    [accountSwitcherData, cookieValues],
  );

  const links = useCallback(() => {
    if (!activeAccount) return [];
    const links = defaultLinks(getActiveAccountSlug(activeAccount));
    if (eligibleForAddTech && context === 'product') {
      return [...links, addTechLink];
    }

    return links;
  }, [eligibleForAddTech, context, activeAccount]);

  const logout = () => {
    if (window?.Intercom) {
      shutdown();
    }
    window.location.assign(`${baseUrl}/logout`);
  };

  const addBusinessAccountOnClick = () => {
    setDomainCookie('checkoutContext', 'ADD_NEW_BUSINESS');
    router.push('/business/products');
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={cn({ 'bg-blue-500': !hasLoggedInCustomer })} />
      {isMobileApp && <MobileAppNavBar />}
      {hasLoggedInCustomer && accountSwitcherData && !isMobileApp && (
        <>
          <ExistingCustomerNavBar
            addBusinessAccountOnClick={addBusinessAccountOnClick}
            name={accountSwitcherData.customerName}
            email={accountSwitcherData.email ?? ''}
            activeAccount={activeAccount ?? accountSwitcherData.accounts[0]}
            accounts={accountSwitcherData.accounts}
            logoLink={`${baseUrl}/account`}
            links={links()}
            onLogout={logout}
          />
          <AccountBanner navigateBack={isProductDescriptionPage} />
        </>
      )}
      <div className="flex w-full flex-col">{children}</div>

      {!isProductDescriptionPage && !isAboutPage && !isMobileApp && (
        <TrustPilotBanner
          /**
           * On the Business PLP, the `TrustPilotBanner` renders directly before the `<Footer />`.
           * Both have the same background colour, so we need to add a border to separate them.
           */
          enableBorderBottom={consumerIsBusiness}
          dataTestId="trust-pilot-banner"
        />
      )}
      {!isRayloPay && consumerIsPersonal && <BenefitsInfo items={benefitsListItems} />}
      {isRayloPay && (
        <div className="bg-white px-6 py-8">
          <BenefitsInfo items={benefitsListItems} />
        </div>
      )}
      {!isMobileApp && <Footer />}
      {['products', 'product'].includes(context) && <SmbInfoModal />}
    </ThemeProvider>
  );
};
