import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { IDivider } from './types';

const StyledDivider = styled.hr<IDivider>`
  border: 0;
  height: ${px2Rem(1)};

  // Temporary fix to add browser default to 'hr' element
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  // End of temporary fix

  ${({ height }) => height && `height: ${px2Rem(height)};`}
  ${({ color }) => color && `background: ${color};`}
  ${({ padding }) => padding && `margin: 0 ${px2Rem(padding)};`}
  ${({ width }) => width && `width: ${width};`}
`;

export { StyledDivider };
