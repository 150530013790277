import { gql } from '../generated';

export const GET_INTERCOM = gql(`
  query AccountIntercomQuery {
  customer {
    id
    email
    messagingDetails {
      intercom {
        appId
        webUserHash
        userId
      }
    }
  }
}`);
