import { CSS_FONT_WEIGHT, palette } from 'uibook';
import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { px2Rem } from './../../utils/px2Rem';
import styled from 'styled-components';

const StyledTrustPilotBanner = styled.div<{ $enableBorderBottom?: boolean }>`
  ${({ theme }) => `background-color: ${theme?.trustPilot?.backgroundColor};`}
  ${({ theme }) => `color: ${theme?.trustPilot?.color};`}
  padding: ${px2Rem(24)};
  overflow-x: hidden;

  ${({ $enableBorderBottom }) =>
    $enableBorderBottom && `border-bottom: 1px solid ${palette.charcoal[400]};`}
`;

const StyledTrustPilotBannerHeader = styled.h3`
  font-size: ${px2Rem(16)};
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  color: ${palette.white};

  @media (min-width: ${NEW_BREAKPOINTS.tablet}px) {
    font-size: ${px2Rem(20)};
  }
`;

export { StyledTrustPilotBanner, StyledTrustPilotBannerHeader };
