import Vercel from './svgs/vercel.svg';
import Bullet from './svgs/bullet.svg';
import Logo from './svgs/rayloLogo.svg';
import Twitter from './svgs/twitter.svg';
import Instagram from './svgs/instagram.svg';
import Facebook from './svgs/facebook.svg';
import NavLogo from './svgs/rayloNavLogo.svg';
import Chevron from './svgs/chevron.svg';
import VintageTV from './svgs/streamline-icon-vintage-tv.svg';
import TrustPilot from './svgs/trustpilot-1.svg';
import Shipment from './svgs/shipment.svg';
import Close from './svgs/close.svg';
import ShieldPhone from './svgs/phoneshield.svg';
import CalendarHeart from './svgs/icon/streamline/calendar-favorite-heart.svg';
import CheckShield from './svgs/icon/streamline/check-shield.svg';
import CreditCardCheck from './svgs/icon/streamline/credit-card-check.svg';
import PhoneRefresh from './svgs/icon/streamline/icon-streamline-phone-action-refresh.svg';
import Upgrade from './svgs/icon/streamline/upgrade.svg';
import Warranty from './svgs/icon/streamline/warranty.svg';
import PhoneSelfie from './svgs/icon/streamline/phone-selfie-front.svg';
import Wallet from './svgs/icon/streamline/wallet.svg';
import Calendar from './svgs/icon/streamline/calendar.svg';
import Add from './svgs/icon/streamline/add.svg';
import Subtract from './svgs/icon/streamline/minus.svg';
import CertifiedPhoneShield from './svgs/certifiedphoneshield.svg';
import CertifiedWarranty from './svgs/certifiedwarranty.svg';
import ScreenProtector from './svgs/screen-protector.svg';
import PhoneCharger from './svgs/charger.svg';
import PhoneDoubleCamera from './svgs/phone-double-camera.svg';
import CustomerSupport36px from './svgs/customer-support-human-36px.svg';
import PhoneDoubleCamera36px from './svgs/phone-double-camera-36.svg';
import EcologyGlobe36px from './svgs/ecology-globe-nature-36px.svg';
import CertifiedPhoneShield36px from './svgs/shield-phone-36px.svg';
import RefurbCondition24px from './svgs/icon/streamline/RefurbCondition.svg';
import RayloPayTech from './svgs/raylo_pay/RP_tech.svg';
import RayloPayUpgrade from './svgs/raylo_pay/RP_upgrade.svg';
import RayloPayWallet from './svgs/raylo_pay/RP_wallet.svg';
import EcoGlobe from './svgs/icon/icon-custom-ecology-globe-nature.svg';
import RayloPayUpgradeArrow from './svgs/icon-streamline-upgrade.svg';
import RayloPayMonthlyWallet from './svgs/icon-custom-wallet.svg';
import RayloPayCardCheck from './svgs/icon-streamline-credit-card-check.svg';
import Trustpilot2 from './svgs/trustpilot.svg';
import ItemShipment from './svgs/icon-streamline-shipment-upload.svg';
import ItemWarranty from './svgs/icon-streamline-warranty.svg';
import BackArrow from './svgs/icon-custom-back-arrow.svg';
import RayloPayLogo from './svgs/raylo_pay/logo-raylo-pay.svg';
import PoweredByRayloPayLogo from './svgs/logo_powered-by-raylo-pay.svg';
import Form from './svgs/form.svg';
import PreApproved from './svgs/pre-approved.svg';
import Info from './svgs/icon-custom-info.svg';
import Alert from './svgs/alert.svg';
import Laptop from './svgs/icon/streamline/laptop.svg';
import Flash from './svgs/icon/streamline/flash.svg';
import CustomerSupport from './svgs/icon/streamline/customer-support.svg';
import Devices from './svgs/icon/streamline/devices.svg';

export const icons = [
  {
    iconName: 'Vercel',
    icon: <Vercel />,
  },
  {
    iconName: 'Form',
    icon: <Form />,
  },
  {
    iconName: 'Bullet',
    icon: <Bullet />,
  },
  {
    iconName: 'Logo',
    icon: <Logo />,
  },
  {
    iconName: 'Twitter',
    icon: <Twitter />,
  },
  {
    iconName: 'Instagram',
    icon: <Instagram />,
  },
  {
    iconName: 'Facebook',
    icon: <Facebook />,
  },
  {
    iconName: 'NavLogo',
    icon: <NavLogo />,
  },
  {
    iconName: 'Chevron',
    icon: <Chevron />,
  },
  {
    iconName: 'VintageTv',
    icon: <VintageTV />,
  },
  {
    iconName: 'TrustPilot',
    icon: <TrustPilot />,
  },
  {
    iconName: 'Shipment',
    icon: <Shipment />,
  },
  {
    iconName: 'Close',
    icon: <Close />,
  },
  {
    iconName: 'ShieldPhone',
    icon: <ShieldPhone />,
  },
  {
    iconName: 'CalendarHeart',
    icon: <CalendarHeart />,
  },
  {
    iconName: 'CheckShield',
    icon: <CheckShield />,
  },
  {
    iconName: 'CreditCardCheck',
    icon: <CreditCardCheck />,
  },
  {
    iconName: 'PhoneRefresh',
    icon: <PhoneRefresh />,
  },
  {
    iconName: 'Upgrade',
    icon: <Upgrade />,
  },
  {
    iconName: 'Warranty',
    icon: <Warranty />,
  },
  {
    iconName: 'PhoneSelfie',
    icon: <PhoneSelfie />,
  },
  {
    iconName: 'Wallet',
    icon: <Wallet />,
  },
  {
    iconName: 'Calendar',
    icon: <Calendar />,
  },
  {
    iconName: 'Add',
    icon: <Add />,
  },
  {
    iconName: 'Subtract',
    icon: <Subtract />,
  },
  {
    iconName: 'CertifiedPhoneShield',
    icon: <CertifiedPhoneShield />,
  },
  {
    iconName: 'CertifiedWarranty',
    icon: <CertifiedWarranty />,
  },
  {
    iconName: 'ScreenProtector',
    icon: <ScreenProtector />,
  },
  {
    iconName: 'PhoneCharger',
    icon: <PhoneCharger />,
  },
  {
    iconName: 'PhoneDoubleCamera',
    icon: <PhoneDoubleCamera />,
  },
  {
    iconName: 'CustomerSupport36px',
    icon: <CustomerSupport36px />,
  },
  {
    iconName: 'PhoneDoubleCamera36px',
    icon: <PhoneDoubleCamera36px />,
  },
  {
    iconName: 'EcologyGlobe36px',
    icon: <EcologyGlobe36px />,
  },
  {
    iconName: 'CertifiedPhoneShield36px',
    icon: <CertifiedPhoneShield36px />,
  },
  {
    iconName: 'RefurbCondition24px',
    icon: <RefurbCondition24px />,
  },
  {
    iconName: 'RayloPayTech',
    icon: <RayloPayTech />,
  },
  {
    iconName: 'RayloPayUpgrade',
    icon: <RayloPayUpgrade />,
  },
  {
    iconName: 'RayloPayWallet',
    icon: <RayloPayWallet />,
  },
  {
    iconName: 'EcoGlobe',
    icon: <EcoGlobe />,
  },
  {
    iconName: 'RayloPayUpgradeArrow',
    icon: <RayloPayUpgradeArrow />,
  },
  {
    iconName: 'RayloPayMonthlyWallet',
    icon: <RayloPayMonthlyWallet />,
  },
  {
    iconName: 'RayloPayCardCheck',
    icon: <RayloPayCardCheck />,
  },
  {
    iconName: 'Trustpilot2',
    icon: <Trustpilot2 />,
  },
  {
    iconName: 'ItemShipment',
    icon: <ItemShipment />,
  },
  {
    iconName: 'ItemWarranty',
    icon: <ItemWarranty />,
  },
  {
    iconName: 'BackArrow',
    icon: <BackArrow />,
  },
  {
    iconName: 'RayloPayLogo',
    icon: <RayloPayLogo />,
  },
  {
    iconName: 'PoweredByRayloPayLogo',
    icon: <PoweredByRayloPayLogo />,
  },
  {
    iconName: 'PreApproved',
    icon: <PreApproved />,
  },
  {
    iconName: 'Info',
    icon: <Info />,
  },
  {
    iconName: 'Alert',
    icon: <Alert />,
  },
  {
    iconName: 'Laptop',
    icon: <Laptop />,
  },
  {
    iconName: 'Flash',
    icon: <Flash />,
  },
  {
    iconName: 'CustomerSupport',
    icon: <CustomerSupport />,
  },
  {
    iconName: 'Devices',
    icon: <Devices />,
  },
] as const;
