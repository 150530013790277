export const baseUrl = process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL;

export const defaultLinks = (activeAccount: string) => [
  {
    title: 'Account Overview',
    href: `${baseUrl}/account/overview/${activeAccount}`,
  },
  {
    title: 'Account Details',
    href: `${baseUrl}/account/${activeAccount}/your-account`,
  },
  {
    title: 'Referrals',
    href: `${baseUrl}/account/${activeAccount}/refer-a-friend`,
  },
];

export const addTechLink = {
  title: 'View all Additional Tech',
  href: '/products?monthlyprice=pre-approved',
};
