import type { ISocialMediaLinks } from './types';
import { ConsumerTypesEnum, consumerTypePathBase } from 'uibook';

export const footerSocialMediaLinks: ISocialMediaLinks[] = [
  {
    icon: 'Facebook',
    link: 'https://www.facebook.com/joinraylo/',
  },
  {
    icon: 'Instagram',
    link: 'https://www.instagram.com/joinraylo/',
  },
  {
    icon: 'Twitter',
    link: 'https://twitter.com/raylo',
  },
];

export const trustPilotFooterLogo =
  'https://emailsignature.trustpilot.com/signature/en-GB/3/5cdd6c38b4acc6000128faf9/stars.png';

type footerLists = {
  consumerIsBusiness: boolean;
  isRayloPay: boolean;
  showAboutUsLink: boolean;
  showOnlyPhones: boolean;
};
export const getFooterLists = ({
  consumerIsBusiness,
  isRayloPay,
  showAboutUsLink,
  showOnlyPhones,
}: footerLists) => {
  if (isRayloPay) {
    return {
      footerListOne: {
        title: 'Raylo Pay',
        links: [
          {
            label: 'Phones',
            link: '/phones',
          },
          ...(showOnlyPhones
            ? []
            : [
                {
                  label: 'Tablets',
                  link: '/tablets',
                },
                {
                  label: 'Laptops',
                  link: '/laptops',
                },
              ]),
          ...(showAboutUsLink
            ? [
                {
                  label: 'About Us',
                  link: '/about',
                },
                {
                  label: 'How It Works',
                  link: '/about/#how-it-works',
                },
              ]
            : []),
        ],
      },
      footerListTwo: {
        title: 'Customer Service',
        links: [
          {
            label: 'Help',
            link: 'https://help.raylo.com/',
          },
          {
            label: 'Contact Us',
            link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
          },
        ],
      },
    };
  }
  return {
    footerListOne: {
      title: 'Raylo',
      links: [
        {
          label: 'My Account',
          link: 'https://app.raylo.com/login?_ga=2.244162309.1234187286.1648466711-1412900303.1643731306&_gac=1.128649982.1647352336.CjwKCAjw8sCRBhA6EiwA6_IF4T5ULLG4cUeaWZgjpyMtdEqx50wWKdJgbZkr3e2dviPbJmIEYPMG0RoCXXsQAvD_BwE',
        },
        {
          label: 'Sustainability',
          link: 'https://www.raylo.com/sustainability',
        },
        {
          label: 'How it works',
          link: `https://www.raylo.com${consumerIsBusiness ? consumerTypePathBase[ConsumerTypesEnum.BUSINESS] : '/'}#how-it-works`,
        },
        {
          label: 'Pricing',
          link: 'https://www.raylo.com/#pricing',
        },
        {
          label: 'Blog',
          link: 'https://www.raylo.com/blog',
        },
        {
          label: 'New phones',
          link: 'https://www.raylo.com/brands/new',
        },
        {
          label: 'Refurbished phones',
          link: 'https://www.raylo.com/brands/refurbished',
        },
        {
          label: 'About',
          link: 'https://www.raylo.com/post/iphone-upgrade-programme',
        },
      ],
    },
    footerListTwo: {
      title: 'Customer Service',
      links: [
        {
          label: 'Help',
          link: 'https://help.raylo.com/',
        },
        {
          label: 'Contact Us',
          link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
        },
        {
          label: 'Careers',
          link: 'https://jobs.eu.lever.co/raylo',
        },
        {
          label: 'Guides',
          link: 'https://www.raylo.com/post',
        },
        {
          label: 'Legal',
          link: 'https://www.raylo.com/legal',
        },
        {
          label: 'Students',
          link: 'https://raylo.connect.studentbeans.com/uk',
        },
      ],
    },
  };
};
