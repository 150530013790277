import React from 'react';
import { StyledContainer } from './styles';
import { IContainer } from './types';

/** @deprecated This component is deprecated. Please use CSS instead to avoid unnecessary markup */
const Container = ({
  children,
  ref,
  width,
  maxWidth,
  minWidth,
  height,
  center,
  centerVertical,
  centerHorizontal,
  backgroundColor,
  backgroundImage,
  backgroundImagePosition,
  backgroundImageSize,
  flexRow,
  padding,
  dataTestId,
  spaceBetween,
  alignRight,
  alignBottom,
  minHeight,
  alignCenter,
  justifyCenter,
  margin,
  borderBottom,
  boxShadow,
  border,
  styles,
  noPadding,
  onClick,
  cursorPointer,
  columnReverse,
  gridColumn,
  sticky,
  stickyTop,
  isBinkiesTarget,
}: IContainer) => {
  return (
    <StyledContainer
      data-testid={dataTestId}
      data-binkies-target={isBinkiesTarget}
      ref={ref}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      height={height}
      minHeight={minHeight}
      center={center}
      centerVertical={centerVertical}
      centerHorizontal={centerHorizontal}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundImagePosition={backgroundImagePosition}
      backgroundImageSize={backgroundImageSize}
      flexRow={flexRow}
      padding={padding}
      spaceBetween={spaceBetween}
      alignRight={alignRight}
      alignBottom={alignBottom}
      alignCenter={alignCenter}
      justifyCenter={justifyCenter}
      margin={margin}
      borderBottom={borderBottom}
      boxShadow={boxShadow}
      border={border}
      style={styles}
      noPadding={noPadding}
      onClick={onClick}
      cursorPointer={cursorPointer}
      columnReverse={columnReverse}
      gridColumn={gridColumn}
      sticky={sticky}
      stickyTop={stickyTop}
    >
      {children}
    </StyledContainer>
  );
};

export default Container;
